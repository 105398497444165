import { Component, Grid, Point, View, Graphics, Rect, ColorLike, RoomApi, getRandomItem } from 'outpost';
import { TileData, TileKind } from './map-tiles.ts';
import { DISPLAY_MAP_BACKGROUND, TILE_SIZE } from '../constants.ts';
import { Layer } from '../layer.ts';
import { GameRoom } from '../game-room.ts';
import { Opponent } from '../opponent.ts';
import { MapData } from '../game-data.ts';
import { Exit } from '../exit.ts';
import { OpponentScript } from './map-file.ts';

export class GameMap implements Component {
    game: GameRoom;
    tiles: Grid<TileData>;
    time: number = 0;
    data: MapData;
    requiredKillCount: number;
    optionalKillCount: number;
    exit: Exit;
    scheduledSpawns: Set<[number, OpponentScript]> = new Set();

    constructor(game: GameRoom, levelId: string) {
        this.game = game;
        this.data = structuredClone(game.data.levels[levelId]);
        this.tiles = new Grid({
            width: this.data.width,
            height: this.data.height,
            makeItem: () => undefined as any
        });
        this.tiles.items = structuredClone(this.data.tiles);
        this.requiredKillCount = Object.values(this.data.opponents).filter(opponent => !opponent.optional).length;
        this.optionalKillCount = Object.values(this.data.opponents).length;
        this.exit = new Exit(this.game, this.getTileCenter(this.tiles.width - 1, 7));
    }

    getWidth(): number {
        return this.tiles.width * TILE_SIZE;
    }

    getHeight(): number {
        return this.tiles.height * TILE_SIZE;
    }

    getTileAt(x: number, y: number): TileData | undefined {
        let gridX = Math.floor(x / TILE_SIZE);
        let gridY = Math.floor(y / TILE_SIZE);

        return this.tiles.get(gridX, gridY);
    }

    isWalkableTile(x: number, y: number): boolean {
        let data = this.getTileAt(x, y);

        return data?.kind === TileKind.Empty;
    }

    getTileCenter(gridX: number, gridY: number): Point {
        return Rect.resolve({
            x1: gridX * TILE_SIZE,
            y1: gridY * TILE_SIZE,
            width: TILE_SIZE,
            height: TILE_SIZE,
        }).getCenter();
    }

    notifyOpponentKilled(opponent: Opponent | null) {
        if (!opponent || !opponent.script.optional) {
            this.requiredKillCount -= 1;
        }

        this.optionalKillCount -= 1;

        if (this.requiredKillCount <= 0) {
            this.game.items.add(this.exit);
        }
    }

    update(api: RoomApi, elapsedSecs: number) {
        this.time += elapsedSecs;

        let colors: string[] = [];

        for (let player of this.game.players) {
            let tile = this.getTileAt(player.position.x, player.position.y);
            colors.push(tile?.color ?? '');
        }

        for (let id in this.data.opponents) {
            let script = this.data.opponents[id];

            if (!script.done && ((this.time > script.triggerTime && !script.triggerColor) || (colors.includes(script.triggerColor!)))) {
                this.scheduledSpawns.add([0, script]);

                script.done = true;
            }
        }

        for (let item of this.scheduledSpawns) {
            let script = item[1];

            item[0] += elapsedSecs;

            if (item[0] > script.spawnDelay) {
                let opponent = new Opponent(this.game, script);
                this.game.opponents.add(opponent);
                this.scheduledSpawns.delete(item);

                api.now().playAudio({
                    audioUrl: getRandomItem(opponent.assets.spawnSound),
                    volume: 0.5
                });
            }
        }

        if (this.game.items.has(this.exit)) {
            for (let player of this.game.players) {
                if (this.game.map.getWidth() - player.position.x < player.size / 2 + 1) {
                    this.game.mustAdvance = true;
                    break;
                }
            }
        }
    }

    render(view: View): void {
        if (!DISPLAY_MAP_BACKGROUND) {
            for (let { x, y, value } of this.tiles.cells()) {
                let tileGraphics: Graphics = {
                    key: `tile-${x}-${y}`,
                    // angle: 2 * Math.PI,
                    layerId: Layer.Tiles,
                    detectable: false,
                    rect: {
                        x1: x * TILE_SIZE,
                        y1: y * TILE_SIZE,
                        width: TILE_SIZE,
                        height: TILE_SIZE,
                    },
                    color: value.color as ColorLike,
                    brightness: 0.8
                };

                view.paint(tileGraphics);
            }
        } else {
            let width = this.getWidth();
            let height = this.getHeight();

            for (let i = 0; i < this.data.backgroundImageUrl.length; ++i) {
                let url = this.data.backgroundImageUrl[i];
                let x1 = width / this.data.backgroundImageUrl.length * i;
                let w = width / this.data.backgroundImageUrl.length;

                view.paint({
                    key: `background-${i}`,
                    layerId: Layer.Tiles,
                    image: url,
                    rect: Rect.from({ x1, y1: 0, width: w, height }),
                    brightness: 0.8
                });
            }
        }

        let infoRect = Rect.from({ x1: 10, y1: 10, width: 200, height: 200 });
        let infoText = [
            `Level ${this.game.levelIndex + 1}`,
            `Attempt ${this.game.attemptNumber}`,
            `Difficulty: ${this.game.players.size} player${this.game.players.size > 1 ? 's' : ''}`,
            ``,
            `Move: ZQSD / WASD`,
            `Shoot: Left click2`,
            `Leaderboard: Tab`,
        ].join('\n\n');

        // view.paint({
        //     key: 'controls-bg',
        //     layerId: Layer.OverworldUi,
        //     color: 'white',
        //     rect: infoRect.pad(5)
        // });

        // view.paint({
        //     key: 'controls-border',
        //     layerId: Layer.OverworldUi,
        //     color: 'black',
        //     strokeSize: 3,
        //     rect: infoRect.pad(6)
        // });

        view.paint({
            key: 'controls',
            layerId: Layer.Tiles,
            text: infoText,
            verticalAlign: 'top',
            horizontalAlign: 'left',
            textSize: 15,
            textMultiline: true,
            rect: infoRect
        });
    }
}
globalThis.ALL_FUNCTIONS.push(GameMap);