import { Component, Point, RoomApi, View } from 'outpost';
import { GameRoom } from './game-room.ts';
import { Layer } from './layer.ts';

export const EXIT_SIZE = 300;

export class Exit implements Component {
    game: GameRoom;
    position: Point;
    showIndicator: boolean = true;

    constructor(game: GameRoom, position: Point) {
        this.game = game;
        this.position = position;
    }

    render(view: View): void {
        view.paint({
            layerId: Layer.TilesUi,
            x: 1600 - EXIT_SIZE / 2,
            y: 450,
            width: EXIT_SIZE,
            height: EXIT_SIZE,
            image: 'assets/sprites/victory-arrow.png'
        });
    }
}
globalThis.ALL_FUNCTIONS.push(Exit);