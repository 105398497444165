import { KeyCode, Vector } from 'outpost';
import { Direction } from './direction.ts';
import { OpponentAssets, OpponentStats } from './opponent-stats.ts';

export { default as PLAYER_IDLE_IMG } from '../assets/sprites/player-idle.png';
export { default as PLAYER_FIRING_1_IMG } from '../assets/sprites/player-firing-1.png';
export { default as PLAYER_FIRING_2_IMG } from '../assets/sprites/player-firing-2.png';
export { default as PLAYER_PROJECTILE_IMG } from '../assets/sprites/bullet-player.png';
export { default as OPPONENT_PROJECTILE_IMG } from '../assets/sprites/bullet-badies.png';

export const LEVELS: string[] = ['lv1', 'lv2', 'lv3', 'lv4', 'lv5', 'lv7', 'lv8', 'lv9'];
// export const LEVELS: string[] = ['lv2'];

export const DISPLAY_MAP_BACKGROUND = true;
export const AUTO_JOIN = false
export const AUTO_LOGIN = true;

export const MIN_CAMERA_X = 800;

export const TILE_SIZE = 60;
export const HALF_TILE_SIZE = TILE_SIZE / 2;

export const PLAYER_SIZE = 1;
export const PLAYER_SPEED = 7;
export const PLAYER_SHOOT_COOLDOWN = 0.3;
export const PLAYER_HEALTH = 3;
export const PLAYER_PROJECTILE_SPEED = 30;
export const PLAYER_PROJECTILE_SIZE = 0.6;
export const PLAYER_PROJECTILE_INACCURACY_ANGLE = 7 / 180 * Math.PI;
export const PLAYER_PROJECTILE_RANGE = 13;

export const DEFAULT_OPPONENT_HEALTH = 3;
export const DEFAULT_OPPONENT_SPEED = 5;
export const DEFAULT_OPPONENT_SIZE = 1;
export const DEFAULT_OPPONENT_PROJECTILE_SPEED = 8;
export const DEFAULT_OPPONENT_PROJECTILE_SIZE = 1.2;
export const DEFAULT_OPPONENT_PROJECTILE_RANGE = 20;

export const OPPONENT_POWER_MULTIPLIER_PER_ADDITIONAL_PLAYER = 1.1;
export const OPPONENT_BONUS_HEALTH_PER_ADDITIONAL_PLAYER = 1;

export const PLAYER_HITBOX_MULTIPLIER = 0.7;
export const FIRE_ANIMATION_DURATION = 0.1;

export const DIRECTION_TO_VECTOR: { [Key in Direction]: Vector } = {
    'top': Vector.top(),
    'right': Vector.right(),
    'bottom': Vector.bottom(),
    'left': Vector.left(),
};

export const KEY_TO_DIRECTION: Partial<{ [Key in KeyCode]: Direction }> = {
    'KeyW': 'top',
    'KeyD': 'right',
    'KeyS': 'bottom',
    'KeyA': 'left',
};

export const DEFAULT_OPPONENT_ASSETS: OpponentAssets = {
    idle: 'assets/sprites/soldier-idle.png',
    firing1: 'assets/sprites/soldier-firing-1.png',
    firing2: 'assets/sprites/soldier-firing-2.png',
    dead: 'assets/sprites/soldier-dead.png',
    idleAspectRatio: 109 / 100,
    firingAspectRatio: 171 / 116,
    firingOffsetX: 20,
    projectileSound: ['assets/sounds/soldier-shot.mp3'],
    spawnSound: ['assets/sounds/soldier-spawn-1.mp3', 'assets/sounds/soldier-spawn-2.mp3']
};

export const OPPONENT_KIND_TO_STATS: { [key: string]: Partial<OpponentStats> } = {
    boss: {
        health: 60,
        size: DEFAULT_OPPONENT_SIZE * 2,
        projectileSpeed: DEFAULT_OPPONENT_PROJECTILE_SPEED / 2,
        projectileRange: 100,
        bonusHealthPerAdditionalPlayer: 10
    },
    fast: {
        speed: DEFAULT_OPPONENT_SPEED * 1.5
    }
};

export const OPPONENT_KIND_TO_ASSETS: { [key: string]: OpponentAssets } = {
    boss: {
        idle: 'assets/sprites/boss-idle.png',
        firing1: 'assets/sprites/boss-firing-1.png',
        firing2: 'assets/sprites/boss-firing-2.png',
        dead: 'assets/sprites/boss-dead.png',
        idleAspectRatio: 195 / 183,
        firingAspectRatio: 299 / 187,
        firingOffsetX: 40,
        projectileSound: ['assets/sounds/boss-shot.mp3'],
        spawnSound: ['assets/sounds/boss-spawn.mp3']
    }
};