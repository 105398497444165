import { readFileSync } from 'fs';
import { Point, logWarning } from 'outpost';
import { TileData } from './map-tiles.ts';
import { basename } from 'path';

export type OpponentScript = {
    id: string;
    kind: string;
    triggerTime: number;
    triggerColor: string | null;
    spawnCoords: [number, number];
    spawnDelay: number;
    actions: OpponentAction[];
    optional: boolean;
    done: boolean;
};

export type MoveAction = {
    kind: 'move';
    target: [number, number];
};

export type WaitAction = {
    kind: 'wait';
    durationSecs: number;
};

export type ShootAction = {
    kind: 'shoot';
    angle: number;
    target?: string;
};

export type LabelAction = {
    kind: 'label';
    label: string;
};

export type LoopAction = {
    kind: 'loop';
    label: string;
};

export type FadeAction = {
    kind: 'fade';
    durationSecs: number;
    respawnCoords?: [number, number]
};

export type TurnAction = {
    kind: 'turn';
    angle: number | 'player';
}

export type OpponentAction = MoveAction | WaitAction | ShootAction | LabelAction | LoopAction | FadeAction | TurnAction;

export function parseMapOpponents(filePath: string, tiles: TileData[]): { [key: string]: OpponentScript; } {
    let content = readFileSync(filePath, 'utf8');
    let lines = content.replaceAll(',', '.').split('\n').filter(x => x);
    let opponents: { [key: string]: OpponentScript; } = {};
    let currentId = '';

    for (let line of lines) {
        if (line.trim() === '') {
            continue;
        }

        let [id, keyword, ...params] = line.split('\t').map(str => str.trim());

        id ||= currentId;
        currentId = id;

        let opponent = opponents[id];

        if (!opponent) {
            opponent = {
                id,
                kind: 'default',
                spawnCoords: [0, 0],
                spawnDelay: 0,
                triggerColor: null,
                triggerTime: 0,
                optional: false,
                actions: [],
                done: false
            };
            opponents[id] = opponent;
        }

        if (keyword === 'kind') {
            let [kind] = params;

            opponent.kind = kind;
        } else if (keyword === 'spawn') {
            let [x, y, delay] = params;

            opponent.spawnCoords = [parseFloat(x), parseFloat(y)];
            opponent.spawnDelay = parseFloat(delay) || 0;
        } else if (keyword === 'timer') {
            let [spawnTime] = params;

            opponent.triggerTime = parseFloat(spawnTime);
        } else if (keyword === 'trigger') {
            let [color] = params;

            opponent.triggerColor = `#${color.toUpperCase()}`;

            if (!tiles.find(tile => tile.color === opponent.triggerColor)) {
                logWarning(`No spawn tile found for opponent #${id}`);
            }
        } else if (keyword === 'optional') {
            opponent.optional = true;
        } else if (keyword === 'label') {
            let [label] = params;

            opponent.actions.push({
                kind: 'label',
                label
            });
        } else if (keyword === 'move') {
            let [x, y] = params;

            opponent.actions.push({
                kind: 'move',
                target: [parseFloat(x), parseFloat(y)]
            });
        } else if (keyword === 'shoot') {
            let [angle, target] = params;

            opponent.actions.push({
                kind: 'shoot',
                angle: toRadians(parseFloat(angle)),
                target: target
            });
        } else if (keyword === 'wait') {
            let [duration] = params;

            opponent.actions.push({
                kind: 'wait',
                durationSecs: parseFloat(duration)
            });
        } else if (keyword === 'loop') {
            let [label] = params;

            opponent.actions.push({
                kind: 'loop',
                label
            });

            if (!opponent.actions.find(action => action.kind === 'label' && action.label === label)) {
                logWarning(`file ${basename(filePath)}: opponent #${id}: no label "${label}" found`);
            }
        } else if (keyword === 'turn') {
            let [angle] = params;

            opponent.actions.push({
                kind: 'turn',
                angle: parseFloat(angle || '0')
            });

        } else if (keyword === 'spread') {
            let [centerAngleStr, stepStr, countStr, waitStr] = params;
            let centerAngle = toRadians(parseFloat(centerAngleStr));
            let step = toRadians(parseFloat(stepStr || '30'));
            let count = parseFloat(countStr || '3');
            let waitDuration = parseFloat(waitStr) || 0.1;
            let target = isNaN(centerAngle) ? centerAngleStr : undefined;
            let baseAngle = !isNaN(centerAngle) ? centerAngle : 0;
            let angle = baseAngle - step * (count - 1) / 2;

            if (waitStr === '0') {
                waitDuration = 0;
            }

            opponent.actions.push({
                kind: 'turn',
                angle: target ? 'player' : baseAngle
            });

            for (let i = 0; i < count; ++i) {
                opponent.actions.push({ kind: 'shoot', angle, target });
                opponent.actions.push({ kind: 'wait', durationSecs: waitDuration });

                angle += step;
            }

            opponent.actions.pop();
        } else if (keyword === 'fade') {
            let [duration, x, y] = params;
            let respawnCoords: [number, number] | undefined = undefined;

            if (!isNaN(parseFloat(x)) && !isNaN(parseFloat(y))) {
                respawnCoords = [parseFloat(x), parseFloat(y)];
            }

            opponent.actions.push({
                kind: 'fade',
                durationSecs: parseFloat(duration || '1'),
                respawnCoords
            });
        } else {
            logWarning(`unknown keyword "${keyword}"`);
        }
    }

    return opponents;
}

function toRadians(degrees: number) {
    return degrees / 180 * Math.PI;
}