import { Component, KeyCode, Point, Room, RoomApi, View, clamp } from 'outpost';
import { Player } from './player.ts';
import { GameMap } from './map/map.ts';
import { DISPLAY_MAP_BACKGROUND, KEY_TO_DIRECTION, LEVELS, MIN_CAMERA_X, OPPONENT_BONUS_HEALTH_PER_ADDITIONAL_PLAYER, OPPONENT_POWER_MULTIPLIER_PER_ADDITIONAL_PLAYER, PLAYER_SPEED, TILE_SIZE } from './constants.ts';
import { Opponent } from './opponent.ts';
import { Layer, OVERWORLD_LAYERS } from './layer.ts';
import { Projectile } from './projectile.ts';
import { loadGameData } from './game-data.ts';
import { EndOfGamePanel } from './victory-panel.ts';
import { isAngleTowardsRight } from './utils.ts';
import { BackgroundMusic } from './background-music.ts';
import { Direction } from './direction.ts';
import { Leaderboard } from './leaderboard.ts';

export class GameRoom implements Room<Player> {
    data = loadGameData();
    players: Set<Player> = new Set();
    opponents: Set<Opponent> = new Set();
    projectiles: Set<Projectile> = new Set();
    items: Set<Component> = new Set();
    map!: GameMap;
    levelIndex: number = 0;
    paused: boolean = true;
    ended: boolean = false;
    mustRestart: boolean = false;
    mustAdvance: boolean = false;
    opponentPowerMultiplier: number = 1;
    additionalPlayerCount: number = 0;
    randomValues: number[] = [];
    attemptNumber: number = 1;
    loaded: boolean = false;
    backgroundMusic = new BackgroundMusic();
    leaderboard = new Leaderboard(this);

    constructor() {
        for (let i = 0; i < 1000; ++i) {
            this.randomValues.push(Math.random());
        }
    }

    onClientAdded(api: RoomApi, player: Player): void {
        this.players.add(player);
        player.game = this;
    }

    onClientDisconnected(api: RoomApi, player: Player): void {
        player.connected = false;

        if ([...this.players].every(player => !player.connected)) {
            api.deleteRoom(GameRoom, api.roomId);
        }
    }

    onClientReconnected(api: RoomApi, player: Player): void {
        player.connected = true;
    }

    onMount(api: RoomApi): void {
        api.initLayers<Layer>({
            [Layer.Tiles]: {},
            [Layer.TilesUi]: {},
            [Layer.Items]: {},
            [Layer.Projectiles]: {},
            [Layer.Opponents]: {},
            [Layer.Players]: {},
            [Layer.OverworldUi]: {},
            [Layer.Loading]: {},
        });
        api.configureRenderer({
            backgroundColor: 'black'
        });

        let urls = Object.values(this.data.levels).map(level => level.backgroundImageUrl).flat();

        if (DISPLAY_MAP_BACKGROUND) {
            Promise.all(urls.map(url => api.loadImage(url, { waitForLoading: false }))).then(() => {
                api.update(this, () => this.onLoad(api));
            });
        } else {
            this.onLoad(api);
        }
    }

    onStart(api: RoomApi): void {
        this.loadLevel(api);
        this.paused = false;
        this.opponentPowerMultiplier = OPPONENT_POWER_MULTIPLIER_PER_ADDITIONAL_PLAYER ** (this.players.size - 1);
        this.additionalPlayerCount = OPPONENT_BONUS_HEALTH_PER_ADDITIONAL_PLAYER * (this.players.size - 1);
    }

    onLoad(api: RoomApi) {
        this.loaded = true;
        api.render(this.backgroundMusic);
    }

    loadLevel(api: RoomApi) {
        let levelId = LEVELS[this.levelIndex];

        if (!levelId) {
            this.paused = true;
            this.ended = true;
            return;
        }

        api.unmount(this.map);
        api.unmount(this.players);
        api.unmount(this.opponents);
        api.unmount(this.projectiles);
        api.unmount(this.items);

        this.map = new GameMap(this, levelId);
        this.opponents.clear();
        this.projectiles.clear();
        this.items.clear();

        let startY = 8 - Math.min(6, this.players.size);
        let x = 0;
        let y = startY;

        for (let player of this.players) {
            player.reset(this.map.getTileCenter(x, y));
            y += 2;

            if (y >= 14) {
                y = startY;
                x += 2;
            }
        }

        api.update(this);
    }

    loadNextLevel(api: RoomApi) {
        this.levelIndex += 1;
        this.loadLevel(api);
    }

    getClosestPlayer(position: Point): Player {
        let result!: Player;
        let currentDistance = Infinity;

        for (let player of this.players) {
            let distance = player.position.getDistanceTo(position);

            if (distance < currentDistance) {
                currentDistance = distance;
                result = player;
            }
        }

        return result;
    }

    onUpdate(api: RoomApi): void {
        if (this.paused) {
            return;
        }

        let elapsedSecs = api.getServerTickDuration() / 1000;

        this.map.update(api, elapsedSecs);

        for (let player of this.players) {
            player.update(api, elapsedSecs);
        }

        for (let opponent of this.opponents) {
            opponent.update(api, elapsedSecs);

            if (opponent.isDead) {
                this.opponents.delete(opponent);
                api.unmount(opponent);
            }
        }

        for (let projectile of this.projectiles) {
            projectile.update(api, elapsedSecs);

            if (projectile.dead) {
                this.projectiles.delete(projectile);
                api.unmount(projectile);
            }
        }

        api.update(this.players);
        api.update(this.opponents);
        api.update(this.projectiles);
        api.update(this.items);

        let activePlayer = api.getActiveClient() as Player | null;

        if (activePlayer) {
            let minCameraX = MIN_CAMERA_X;
            let maxCameraX = this.map.getWidth() - MIN_CAMERA_X;
            let cameraX = clamp(activePlayer.position.x, minCameraX, maxCameraX);

            if (maxCameraX < minCameraX) {
                cameraX = (minCameraX + maxCameraX) / 2;
            }

            api.updateLayer(OVERWORLD_LAYERS, { cameraX });

            for (let item of this.items) {
                (item as any).refresh?.(api);
            }
        }

        if (this.mustRestart) {
            this.attemptNumber += 1;
            this.loadLevel(api);
        } else if (this.mustAdvance) {
            this.loadNextLevel(api);
        }

        this.mustRestart = false;
        this.mustAdvance = false;
    }

    $startMoveDown(api: RoomApi, player: Player) { return this.move(api, player, 'KeyS', 'down'); }
    $stopMoveDown(api: RoomApi, player: Player) { return this.move(api, player, 'KeyS', 'up'); }
    $startMoveUp(api: RoomApi, player: Player) { return this.move(api, player, 'KeyW', 'down'); }
    $stopMoveUp(api: RoomApi, player: Player) { return this.move(api, player, 'KeyW', 'up'); }
    $startMoveLeft(api: RoomApi, player: Player) { return this.move(api, player, 'KeyA', 'down'); }
    $stopMoveLeft(api: RoomApi, player: Player) { return this.move(api, player, 'KeyA', 'up'); }
    $startMoveRight(api: RoomApi, player: Player) { return this.move(api, player, 'KeyD', 'down'); }
    $stopMoveRight(api: RoomApi, player: Player) { return this.move(api, player, 'KeyD', 'up'); }

    async move(api: RoomApi, player: Player, key: KeyCode, trigger: 'down' | 'up') {
        let input = await api.waitForUserInput({
            shortcuts: { [key]: {} },
            shortcutTrigger: trigger
        });

        let direction = (KEY_TO_DIRECTION as any)[input.button] as Direction;

        await api.waitForServerResponse();

        let pressed = trigger === 'down';

        player.directions[direction] = pressed;
    }

    async $startShoot(api: RoomApi, player: Player) {
        await api.waitForButtonPress('MouseLeft');
        await api.waitForServerResponse();

        player.shooting = true;
    }

    async $stopShoot(api: RoomApi, player: Player) {
        await api.waitForButtonRelease('MouseLeft');
        await api.waitForServerResponse();

        player.shooting = false;
    }

    async $shoot(api: RoomApi, player: Player) {
        if (player.shooting && player.canShoot()) {
            let target = api.getPointerPosition(Layer.Tiles);
            let angle = player.position.getVectorTo(target).getAngle();

            await api.waitForServerResponse();

            player.shoot(api, angle);

            api.withActiveClient(() => {
                api.shakeLayer(OVERWORLD_LAYERS, {
                    offsetX: 2,
                    offsetY: 2,
                    bounceCount: 1,
                    duration: 100,
                    lossPercentPerBounce: 0.5
                });
            });
        }
    }

    async $turn(api: RoomApi, player: Player) {
        let pointer = api.getPointerPosition(Layer.Tiles);
        let angle = player.position.getVectorTo(pointer).getAngle();
        let isTowardsRight = isAngleTowardsRight(angle) || pointer.isZero();
        let isLoaded = api.getClientData(() => this.loaded);

        if (player.isLookingRight === isTowardsRight || !isLoaded) {
            return;
        }

        await api.waitForServerResponse();

        player.isLookingRight = isTowardsRight;
    }

    async $levelUp(api: RoomApi, player: Player) {
        await api.waitForButtonPress('Shift_KeyC');
        await api.waitForServerResponse();

        this.loadNextLevel(api);
    }

    async $endGame(api: RoomApi, player: Player) {
        if (!this.ended) {
            return;
        }

        api.update(this.players, player => { player.isMoving = false; });
        api.update(this.opponents, opponent => { opponent.isMoving = false; });

        await api.prompt(new EndOfGamePanel(this.attemptNumber));

        api.unmount(this.backgroundMusic);
        api.unmount(this.leaderboard);

        await api.waitForServerResponse();

        api.removeClientFromRoom(GameRoom, api.roomId, player.id);
    }

    async $win(api: RoomApi) {
        await api.waitForButtonPress('Shift_KeyX');
        await api.waitForServerResponse();

        this.paused = true;
        this.ended = true;
    }

    async $restart(api: RoomApi) {
        await api.waitForButtonPress('Shift_KeyR');
        await api.waitForServerResponse();

        this.mustRestart = true;
    }

    async $pause(api: RoomApi) {
        await api.waitForButtonPress('Shift_KeyP');
        await api.waitForServerResponse();

        this.paused = !this.paused;
    }

    async $killAll(api: RoomApi) {
        await api.waitForButtonPress('Shift_KeyV');
        await api.waitForServerResponse();

        for (let player of this.players) {
            player.health = 100;
            player.maxHealth = 100;
            player.speed = PLAYER_SPEED * TILE_SIZE * 3;
        }

        for (let i = 0; i < 100; ++i) {
            this.map.notifyOpponentKilled(null);
        }
    }

    async $toggleLeaderboard(api: RoomApi) {
        await api.waitForButtonPress('Tab');

        api.render(this.leaderboard);

        await api.waitForButtonRelease('Tab');

        api.unmount(this.leaderboard);
    }

    render(view: View): void {
        if (!this.loaded) {
            view.paint({
                layerId: Layer.Loading,
                color: 'black',
            });

            view.paint({
                key: 'text',
                text: 'LOADING',
                textSize: 200,
                textColor: 'white',
            });
        } else {
            view.addChild(this.map);
            view.addChild(this.players);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(GameRoom);