import { Component, Point, PointLike, RoomApi, Vector, View } from 'outpost';
import { Layer } from './layer.ts';
import { GameRoom } from './game-room.ts';
import { TileKind } from './map/map-tiles.ts';
import { Player } from './player.ts';
import { Opponent } from './opponent.ts';
import { PLAYER_HITBOX_MULTIPLIER, OPPONENT_PROJECTILE_IMG, PLAYER_PROJECTILE_IMG } from './constants.ts';

export type ProjectileParams = {
    source: Player | Opponent;
    position: PointLike;
    size: number;
    speed: number;
    angle: number;
    range: number;
};

export class Projectile implements Component {
    game: GameRoom;
    source: Player | Opponent;
    position: Point;
    angle: number;
    size: number;
    speed: number;
    range: number;
    dead: boolean = false;

    constructor(game: GameRoom, params: ProjectileParams) {
        this.game = game;
        this.source = params.source;
        this.position = Point.from(params.position);
        this.speed = params.speed;
        this.size = params.size;
        this.angle = params.angle;
        this.range = params.range;
    }

    update(api: RoomApi, elapsedSecs: number) {
        let length = this.speed * elapsedSecs;
        let velocity = Vector.right().rotate(this.angle).withLength(length);

        this.position = this.position.add(velocity);
        this.range -= length;

        let tile = this.game.map.getTileAt(this.position.x, this.position.y);

        if (this.range <= 0 || tile?.kind !== TileKind.Empty && tile?.kind !== TileKind.Hole) {
            this.dead = true;
            return;
        }

        let targets = this.source instanceof Player ? this.game.opponents : this.game.players;

        for (let target of targets) {
            let distance = this.position.getDistanceTo(target.position);
            let maxDistance = this.size / 2 + target.size / 2;

            if (target instanceof Player) {
                maxDistance *= PLAYER_HITBOX_MULTIPLIER;
            }

            if (target.canBeCollided() && distance < maxDistance) {
                this.dead = true;
                target.takeDamage(api, this.source);
                break;
            }
        }
    }

    render(view: View): void {
        let image = this.source instanceof Player ? PLAYER_PROJECTILE_IMG : OPPONENT_PROJECTILE_IMG;
        let angle = this.angle;

        if (image === OPPONENT_PROJECTILE_IMG) {
            angle += Math.PI / 2;
        }

        view.paint({
            layerId: Layer.Projectiles,
            position: this.position,
            width: this.size,
            height: this.size,
            image,
            angle,
        });  
    }
}
globalThis.ALL_FUNCTIONS.push(Projectile);