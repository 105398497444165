import { ColorLike, Component, View } from 'outpost';
import { ComponentLike } from '../../outpost/src/framework/component/component.ts';
import { GameRoom } from './game-room.ts';

export class Leaderboard implements Component {
    game: GameRoom;

    constructor(game: GameRoom) {
        this.game = game;
    }

    render(view: View): void {
        view.paint({
            rect: view.rect.scale(0.8),
            borderRadius: 10,
            color: 'white'
        });

        view.paint({
            key: 'stroke',
            color: 'black',
            strokeSize: 3,
        });

        let words: string[] = ['##', 'Player', 'Damages', 'Deaths'];
        let players = [...this.game.players];

        players.sort((a, b) => (a.deathCount - b.deathCount) * 100000 + (b.damagesDealt - a.damagesDealt));

        for (let i = 0; i < players.length; ++i) {
            let player = players[i];
            words.push((i + 1).toString(), player.id, player.damagesDealt.toString(), player.deathCount.toString());
        }

        let items = words.map((word, i) => ({
            render(view: View) {
                let textColor: ColorLike = 'black';

                if (i >= 4 && players.length > 1) {
                    textColor = players[(i - 4) / 4 | 0].color;
                }

                view.paint({
                    text: word,
                    textColor
                });
            }
        }))

        view.addGrid({
            items,
            itemAspectRatio: 3,
            rowSize: 4,
            columnSize: [6, Infinity],
        });
    }
}
globalThis.ALL_FUNCTIONS.push(Leaderboard);