import { Component, Point, View } from 'outpost';
import { Layer } from '../layer.ts';
import { Opponent } from '../opponent.ts';

const TRAVELLED_DISTANCE = 400;
const DURATION = 700;

export class OpponentGhost implements Component {
    source: Opponent;
    targetPosition: Point;

    constructor(source: Opponent, killerPosition: Point, n: number) {
        this.source = source;
        this.targetPosition = computeTargetPosition(source, killerPosition, n);
    }

    render(view: View): void {
        view.paint({
            layerId: Layer.Opponents,
            image: this.source.assets.dead,
            imageFlipX: this.source.facingRight,
            position: {
                duration: DURATION,
                start: this.source.position,
                end: this.targetPosition,
                easing: 'quadratic-out'
            },
            width: this.source.size,
            height: this.source.size,
            // alpha: { start: 0.5, end: 0.2 }
        });
    }
}

function computeTargetPosition(source: Opponent, killerPosition: Point, n: number): Point {
    let vector = killerPosition.getVectorTo(source.position);
    let angle = (n * 2 - 1) * Math.PI * 0.3;

    vector = vector.rotate(angle).withLength(TRAVELLED_DISTANCE);

    return source.position.add(vector);
}
globalThis.ALL_FUNCTIONS.push(OpponentGhost);