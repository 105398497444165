export enum Layer {
    Tiles,
    TilesUi,
    Items,
    Projectiles,
    Opponents,
    Players,
    OverworldUi,
    Loading,
};

export const OVERWORLD_LAYERS = [Layer.Tiles, Layer.Items, Layer.Projectiles, Layer.Opponents, Layer.Players, Layer.OverworldUi];