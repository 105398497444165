import { ColorLike, Component, Point, Rect, RoomApi, RoomClient, Vector, View, getRandomItem } from 'outpost';
import { GameRoom } from './game-room.ts';
import { DIRECTION_TO_VECTOR, HALF_TILE_SIZE, PLAYER_FIRING_1_IMG, PLAYER_FIRING_2_IMG, PLAYER_HEALTH, PLAYER_IDLE_IMG, PLAYER_PROJECTILE_INACCURACY_ANGLE, PLAYER_PROJECTILE_RANGE, PLAYER_PROJECTILE_SIZE, PLAYER_PROJECTILE_SPEED, PLAYER_SHOOT_COOLDOWN, PLAYER_SIZE, PLAYER_SPEED, TILE_SIZE } from './constants.ts';
import { Direction } from './direction.ts';
import { Layer } from './layer.ts';
import { Projectile } from './projectile.ts';

export class Player implements RoomClient, Component {
    id: string;
    game!: GameRoom;
    color: ColorLike;
    position: Point = Point.zero();
    size: number = PLAYER_SIZE * TILE_SIZE;
    speed: number = PLAYER_SPEED * TILE_SIZE;
    directions: Partial<{ [Key in Direction]: boolean }> = {};
    shooting: boolean = false;
    shootCooldown: number = -Infinity;
    health: number = 0;
    maxHealth: number = 0;
    isDead: boolean = false;
    isMoving: boolean = false;
    isLookingRight: boolean = true;
    lastHitTime: number = -Infinity;
    connected: boolean = true;
    damagesDealt: number = 0;
    deathCount: number = 0;

    constructor(id: string, params: { color: ColorLike }) {
        this.id = id;
        this.color = params.color;
    }

    reset(position: Point) {
        this.position = position;
        this.health = PLAYER_HEALTH;
        this.maxHealth = this.health;
        this.directions = {};
        this.shootCooldown = 0;
        this.isDead = false;
        this.speed = PLAYER_SPEED * TILE_SIZE;
    }

    update(api: RoomApi, elapsedSecs: number) {
        this.updatePosition(elapsedSecs);
        this.shootCooldown -= elapsedSecs;
    }

    private updatePosition(elapsedSecs: number) {
        let velocity = Vector.zero();

        for (let key in this.directions) {
            let direction = key as Direction;

            if (this.directions[direction]) {
                velocity = velocity.add(DIRECTION_TO_VECTOR[direction]);
            }
        }

        this.isMoving = !velocity.isZero();

        if (!this.isMoving) {
            return;
        }

        let movement = velocity.withLength(elapsedSecs * this.speed);
        let m1 = new Vector(movement.x, 0);
        let m2 = new Vector(0, movement.y);

        this.move(m1);
        this.move(m2);
    }

    private move(movement: Vector) {
        let nextPosition = this.position.add(movement);
        let playerRadius = this.size / 2;
        let isTopLeftWalkable = this.game.map.isWalkableTile(nextPosition.x - playerRadius, nextPosition.y - playerRadius);
        let isTopRightWalkable = this.game.map.isWalkableTile(nextPosition.x + playerRadius, nextPosition.y - playerRadius);
        let isBottomLeftWalkable = this.game.map.isWalkableTile(nextPosition.x - playerRadius, nextPosition.y + playerRadius);
        let isBottomRightWalkable = this.game.map.isWalkableTile(nextPosition.x + playerRadius, nextPosition.y + playerRadius);
        let ok = isTopLeftWalkable && isTopRightWalkable && isBottomLeftWalkable && isBottomRightWalkable;

        if ((!isTopLeftWalkable || !isBottomLeftWalkable) && movement.x < 0) {
            nextPosition.x = snapLower(this.position.x);
        }

        if ((!isTopRightWalkable || !isBottomRightWalkable) && movement.x > 0) {
            nextPosition.x = snapUpper(this.position.x);
        }

        if ((!isTopLeftWalkable || !isTopRightWalkable) && movement.y < 0) {
            nextPosition.y = snapLower(this.position.y);
        }

        if ((!isBottomLeftWalkable || !isBottomRightWalkable) && movement.y > 0) {
            nextPosition.y = snapUpper(this.position.y);
        }

        this.position = nextPosition;
    }

    canShoot() {
        return this.shootCooldown <= 0 && !this.isDead;
    }

    canBeCollided() {
        return !this.isDead;
    }

    shoot(api: RoomApi, angle: number) {
        let factor = api.getRandomNumber() * 2 - 1;

        if (this.shootCooldown > -1) {
            angle += PLAYER_PROJECTILE_INACCURACY_ANGLE * factor;
        }

        let projectile = new Projectile(this.game, {
            source: this,
            position: this.position,
            speed: PLAYER_PROJECTILE_SPEED * TILE_SIZE,
            size: PLAYER_PROJECTILE_SIZE * TILE_SIZE,
            angle: angle,
            range: PLAYER_PROJECTILE_RANGE * TILE_SIZE
        });

        this.game.projectiles.add(projectile);
        this.shootCooldown = PLAYER_SHOOT_COOLDOWN;

        api.now().playAudio({
            audioUrl: getRandomItem(['assets/sounds/player-shot-1.mp3', 'assets/sounds/player-shot-2.mp3', 'assets/sounds/player-shot-3.mp3']),
            volume: 0.2
        });
    }

    setLookRight(value: boolean) {
        this.isLookingRight = value;
    }

    takeDamage(api: RoomApi) {
        this.health -= 1;

        api.now().playAudio({
            audioUrl: 'assets/sounds/player-hurt.mp3'
        });

        if (this.health <= 0) {
            this.deathCount += 1;
            this.isDead = true;
            if ([...this.game.players].every(player => player.isDead)) {
                this.game.mustRestart = true;
            }
        }
    }

    render(view: View): void {
        let rect = new Rect(this.position.x, this.position.y, this.size, this.size);

        view.paint({
            layerId: Layer.Players,
            position: this.position,
            width: this.size,
            height: this.size,
            alpha: this.isDead ? 0.5 : 1,
        });

        let image = PLAYER_IDLE_IMG;
        let firingAspectRatio = 132 / 95;
        let offsetX = 0;
        let facingRight = this.isLookingRight;
        let fireAnimationDuration = 0.1;

        if (this.shooting && !this.isDead) {
            image = this.shootCooldown % fireAnimationDuration < fireAnimationDuration / 2 ? PLAYER_FIRING_1_IMG : PLAYER_FIRING_2_IMG;
            rect = rect.padToMatchAspectRatio(firingAspectRatio);
            offsetX = 20 * (facingRight ? 1 : -1);
        }

        view.paint({
            key: 'body',
            image,
            rect,
            offsetX,
            imageFlipX: !facingRight,
            stretch: this.isMoving ? { start: -0.25, end: 0.25, duration: 100, loop: 'mirror' } : 0,
        });

        if (this.game.players.size > 1) {
            view.paint({
                key: 'body',
                replaceColorSrc: '#3E9FFF',
                replaceColorDst: this.color
            });
        }

        if (!this.isDead) {
            view.paint({
                key: 'health-bg',
                rect: view.rect.fromTopOutwards('*', 10, 5),
                color: 'red'
            });

            view.paint({
                key: 'health-fg',
                rect: view.rect.fromTopOutwards('*', 10, 5),
                fillPercentLinear: this.health / this.maxHealth,
                color: 'chartreuse'
            });
        }

        view.paint({
            key: 'name',
            layerId: Layer.OverworldUi,
            rect: view.rect.fromTopOutwards(this.size * 1.5, 20, 15),
            text: this.id,
            textSize: '100%'
        });
    }
}

function snapLower(coord: number) {
    return Math.floor(coord / HALF_TILE_SIZE) * HALF_TILE_SIZE + 0.000001;
}

function snapUpper(coord: number) {
    return Math.ceil(coord / HALF_TILE_SIZE) * HALF_TILE_SIZE - 0.000001;
}
globalThis.ALL_FUNCTIONS.push(Player);