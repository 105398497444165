import { Component, makePromise, PromiseWithResolvers, Button, RoomApi, View } from 'outpost';

export class EndOfGamePanel implements Component {
    endWhen: PromiseWithResolvers = makePromise();
    button = new Button('Leave');
    attemptCount: number;

    constructor(attemptCount: number) {
        this.attemptCount = attemptCount;
        this.button.setStyle({ base: { background: { color: 'aquamarine' } } });
    }

    async $ok(api: RoomApi) {
        api.setPriority(1);
        await api.waitForItemSelection(this.button);
        this.endWhen.resolve();
    }

    render(view: View): void {
        let rect = view.rect.scale(0.8);
        let [contentRect, buttonRect] = rect.split('top', ['75%']);

        view.paint({
            rect,
            color: 'aliceblue',
            borderRadius: '10%',
        });

        view.paint({
            key: 'stroke',
            color: 'black',
            strokeSize: '2%',
        });

        let subText = this.attemptCount <= 1 ? `And you didn't die once!` : `It took you ${this.attemptCount} attempts.`;
        let text = [
            `|Unsummoming completed:`,
            `|@1.5{YOU WIN}`,
            `|@0.5{${subText} ${getCommentary(this.attemptCount)}}`
        ].join('\n\n');

        view.paint({
            key: 'text',
            rect: contentRect,
            text,
            textMultiline: true,
            textSize: 80,
        });

        buttonRect = buttonRect.scale(0.7).stripToMatchAspectRatio(4);

        view.addChild(this.button, buttonRect);
    }

    getResolvePromise(): Promise<void> {
        return this.endWhen.promise;
    }
}

function getCommentary(attemptCount: number) {
    if (attemptCount <= 1) {
        return `Simply the best.`;
    } else if (attemptCount <= 3) {
        return `Not bad at all.`
    } else if (attemptCount <= 5) {
        return `Not bad.`;
    } else if (attemptCount <= 9) {
        return `Not that bad.`;
    } else if (attemptCount <= 20) {
        return `Well at least you won.`;
    } else {
        return `Did you die endlessly on purpose?`;
    }
}
globalThis.ALL_FUNCTIONS.push(EndOfGamePanel);