import { Component, View } from 'outpost';

export class BackgroundMusic implements Component {
    render(view: View): void {
        view.paint({
            audio: 'assets/sounds/background-music-1.mp3',
            audioLoop: true,
            audioVolume: 0.3
        });
    }
}
globalThis.ALL_FUNCTIONS.push(BackgroundMusic);