import { ColorLike, Grid, PixelGrid, mapObject } from 'outpost';

export enum TileKind {
    Empty,
    Wall,
    Hole
}

export const COLOR_TO_TILE: { [key: string]: TileKind } = {
    '#FFFFFF': TileKind.Empty,
    '#000000': TileKind.Hole,
    '#808080': TileKind.Wall,
};

export const TILE_TO_COLOR: { [Key in TileKind]: ColorLike } = mapObject(COLOR_TO_TILE, (key, value) => [value, key]);

export type TileData = {
    gridX: number;
    gridY: number;
    kind: TileKind;
    color: string;
};

export function parseMapTiles(filePath: string): Grid<TileData> {
    let pixelGrid = PixelGrid.loadFromFile(filePath);
    let tiles = new Grid<TileData>({
        width: pixelGrid.width,
        height: pixelGrid.height,
        makeItem: () => ({} as any),
    });

    for (let gridX = 0; gridX < pixelGrid.width; ++gridX) {
        for (let gridY = 0; gridY < pixelGrid.height; ++gridY) {
            let color = pixelGrid.get(gridX, gridY).toHexString();
            let kind = COLOR_TO_TILE[color] ?? TileKind.Empty;

            tiles.set(gridX, gridY, { gridX, gridY, kind, color });
        }
    }

    return tiles;
}