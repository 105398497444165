import { Component, Point, Rect, RoomApi, View, getRandomItem } from 'outpost';
import { OpponentScript } from './map/map-file.ts';
import { GameRoom } from './game-room.ts';
import { DEFAULT_OPPONENT_HEALTH, DEFAULT_OPPONENT_SIZE, DEFAULT_OPPONENT_SPEED, DEFAULT_OPPONENT_PROJECTILE_SPEED, TILE_SIZE, DEFAULT_OPPONENT_PROJECTILE_SIZE, DEFAULT_OPPONENT_PROJECTILE_RANGE, OPPONENT_KIND_TO_STATS, OPPONENT_KIND_TO_ASSETS, DEFAULT_OPPONENT_ASSETS, FIRE_ANIMATION_DURATION, OPPONENT_BONUS_HEALTH_PER_ADDITIONAL_PLAYER } from './constants.ts';
import { Layer } from './layer.ts';
import { Projectile } from './projectile.ts';
import { OpponentAssets, OpponentStats } from './opponent-stats.ts';
import { Player } from './player.ts';
import { OpponentGhost } from './effects/opponent-ghost.ts';

export class Opponent implements Component {
    game: GameRoom;
    position: Point;
    script: OpponentScript;
    isDead: boolean = false;
    size: number;
    speed: number;
    health: number;
    maxHealth: number;
    projectileSize: number;
    projectileSpeed: number;
    projectileRange: number;
    currentActionIndex: number = 0;
    waitTime: number = 0;
    isMoving: boolean = false;
    isFaded: boolean = false;
    assets: OpponentAssets;
    facingRight: boolean = false;
    shootDuration: number = 0;
    isShooting: boolean = false;
    lastHitTime: number = -Infinity;
    justShot: boolean = false;
    bonusHealthPerAdditionalPlayer: number;

    constructor(game: GameRoom, script: OpponentScript) {
        let stats: Partial<OpponentStats> = OPPONENT_KIND_TO_STATS[script.kind] ?? {};

        this.game = game;
        this.bonusHealthPerAdditionalPlayer = stats.bonusHealthPerAdditionalPlayer ?? OPPONENT_BONUS_HEALTH_PER_ADDITIONAL_PLAYER;
        this.assets = OPPONENT_KIND_TO_ASSETS[script.kind] ?? DEFAULT_OPPONENT_ASSETS;
        this.position = this.game.map.getTileCenter(script.spawnCoords[0], script.spawnCoords[1]);
        this.script = script;
        this.size = (stats.size ?? DEFAULT_OPPONENT_SIZE) * TILE_SIZE;
        this.speed = (stats.speed ?? DEFAULT_OPPONENT_SPEED) * TILE_SIZE * this.game.opponentPowerMultiplier;
        this.health = (stats.health ?? DEFAULT_OPPONENT_HEALTH) + this.game.additionalPlayerCount * this.bonusHealthPerAdditionalPlayer;
        this.maxHealth = this.health;
        this.projectileSize = (stats.projectileSize ?? DEFAULT_OPPONENT_PROJECTILE_SIZE) * TILE_SIZE;
        this.projectileSpeed = (stats.projectileSpeed ?? DEFAULT_OPPONENT_PROJECTILE_SPEED) * TILE_SIZE;
        this.projectileRange = (stats.projectileRange ?? DEFAULT_OPPONENT_PROJECTILE_RANGE) * TILE_SIZE;
    }

    update(api: RoomApi, elapsedSecs: number) {
        let elapsedSecsAugmented = elapsedSecs * this.game.opponentPowerMultiplier;

        this.isMoving = false;
        this.doCurrentAction(api, elapsedSecsAugmented);

        if (this.isShooting) {
            this.shootDuration += elapsedSecs;
        } else {
            this.shootDuration = 0;
        }
    }

    doCurrentAction(api: RoomApi, elapsedSecs: number) {
        let currentAction = this.script.actions[this.currentActionIndex];

        if (!currentAction) {
            return;
        }

        if (currentAction.kind === 'label') {
            this.doNextAction(api, elapsedSecs);
        } else if (currentAction.kind === 'move') {
            let target = this.game.map.getTileCenter(currentAction.target[0], currentAction.target[1]);
            let vector = this.position.getVectorTo(target);
            let distanceTotal = vector.getLength();
            let distanceTravelled = Math.min(elapsedSecs * this.speed, distanceTotal);
            let velocity = vector.withLength(distanceTravelled);

            this.position = this.position.add(velocity);
            this.isMoving = true;
            this.isShooting = false;
            this.facingRight = vector.x > 0;

            if (distanceTotal === distanceTravelled) {
                this.doNextAction(api, 0);
            }
        } else if (currentAction.kind === 'wait') {
            this.waitTime += elapsedSecs;

            if (this.waitTime > 0.1) {
                this.isShooting = false;
            }

            if (currentAction.durationSecs > 0) {
                this.justShot = false;
            }

            if (this.waitTime > currentAction.durationSecs) {
                this.isShooting = false;
                this.waitTime = 0;
                this.doNextAction(api, 0);
            }
        } else if (currentAction.kind === 'shoot') {
            let angle = currentAction.angle;
            this.isShooting = true;

            if (currentAction.target === 'player') {
                let closestPlayer = this.game.getClosestPlayer(this.position);
                let vector = this.position.getVectorTo(closestPlayer.position);

                angle += vector.getAngle();
            } else {
                angle -= Math.PI / 2;
            }

            let projectile = new Projectile(this.game, {
                source: this,
                position: this.position,
                speed: this.projectileSpeed,
                size: this.projectileSize,
                range: this.projectileRange,
                angle: formatAngle(angle)
            });

            if (!this.justShot) {
                api.now().playAudio({
                    audioUrl: getRandomItem(this.assets.projectileSound)
                });
            }

            this.justShot = true;

            // this.facingRight = angle < Math.PI / 2 && angle > -Math.PI / 2
            this.game.projectiles.add(projectile);
            this.doNextAction(api, 0);
        } else if (currentAction.kind === 'fade') {
            if (!this.isFaded) {
                this.isFaded = true;
                // TODO: maybe play animation
            }
            
            this.isShooting = false;
            this.waitTime += elapsedSecs;

            if (this.waitTime > currentAction.durationSecs) {
                if (currentAction.respawnCoords) {
                    this.position = this.game.map.getTileCenter(currentAction.respawnCoords[0], currentAction.respawnCoords[1]);
                }
                
                this.isFaded = false;
                this.waitTime = 0;
                this.doNextAction(api, 0);
            }
        } else if (currentAction.kind === 'turn') {
            let angle = 0;

            if (typeof currentAction.angle === 'number') {
                angle = currentAction.angle - Math.PI / 2;
            } else {
                angle = this.position.getVectorTo(this.game.getClosestPlayer(this.position).position).getAngle();
            }

            angle = formatAngle(angle);

            this.facingRight = angle < Math.PI / 2 && angle > -Math.PI / 2;
            this.doNextAction(api, elapsedSecs);
        } else if (currentAction.kind === 'loop') {
            this.currentActionIndex = this.script.actions.findIndex(action => action.kind === 'label' && action.label === currentAction.label);
            this.doCurrentAction(api, elapsedSecs);
        }
    }

    doNextAction(api: RoomApi, elapsedSecs: number) {
        this.currentActionIndex += 1;
        this.doCurrentAction(api, elapsedSecs);
    }

    takeDamage(api: RoomApi, source: Player | Opponent) {
        this.health -= 1;
        this.lastHitTime = performance.now() / 1000;

        if (source instanceof Player) {
            source.damagesDealt += 1;
        }

        api.now().playAudio({
            audioUrl: 'assets/sounds/opponent-hurt.mp3',
        });

        if (this.health <= 0) {
            this.isDead = true;
            this.game.map.notifyOpponentKilled(this);

            let ghost = new OpponentGhost(this, source.position, this.game.randomValues.pop() ?? Math.random());
            
            this.game.items.add(ghost);
            api.now().playAudio({
                audioUrl: getRandomItem(['assets/sounds/opponent-death-1.mp3', 'assets/sounds/opponent-death-2.mp3']),
                volume: 0.2
            });
        }
    }

    canBeCollided() {
        return !this.isDead && !this.isFaded;
    }

    render(view: View): void {
        if (this.isDead) {
            return;
        }

        let bodyRect = new Rect(this.position.x, this.position.y, this.size, this.size);
        let bodyImage = this.assets.idle;
        let bodyAspectRatio = this.assets.idleAspectRatio;
        let bodyOffsetX = 0;
        let facingRight = this.facingRight;

        if (this.isShooting) {
            bodyImage = this.shootDuration % FIRE_ANIMATION_DURATION < FIRE_ANIMATION_DURATION / 2 ? this.assets.firing1 : this.assets.firing2;
            bodyAspectRatio = this.assets.firingAspectRatio;
            bodyOffsetX = this.assets.firingOffsetX;
        }

        bodyOffsetX *= (facingRight ? 1 : -1)
        bodyRect = bodyRect.padToMatchAspectRatio(bodyAspectRatio);

        let blink = !this.isDead && (performance.now() / 1000 - this.lastHitTime) < 0.05;

        view.paint({
            layerId: Layer.Opponents,
            position: this.position,
            width: this.size,
            height: this.size,
            alpha: this.isFaded ? 0 : 1
        });

        view.paint({
            key: 'body',
            rect: bodyRect,
            image: bodyImage,
            imageFlipX: !facingRight,
            offsetX: bodyOffsetX,
            gradient: 'white',
            gradientProgress: blink ? 1 : 0,
            stretch: this.isMoving ? { start: -0.25, end: 0.25, duration: 100, loop: 'mirror' } : 0
        });

        view.paint({
            key: 'health-bg',
            rect: view.rect.fromTopOutwards('*', 10, 5),
            color: 'red'
        });

        view.paint({
            key: 'health-fg',
            rect: view.rect.fromTopOutwards('*', 10, 5),
            fillPercentLinear: this.health / this.maxHealth,
            color: 'chartreuse'
        });
    }
}

function formatAngle(angle: number): number {
    let result = angle;

    while (result > Math.PI) {
        result -= Math.PI * 2;
    }

    while (result <= -Math.PI) {
        result += Math.PI * 2;
    }

    return result;
}
globalThis.ALL_FUNCTIONS.push(Opponent);