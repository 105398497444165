import { LEVELS } from './constants.ts';
import { OpponentScript, parseMapOpponents } from './map/map-file.ts';
import { TileData, parseMapTiles } from './map/map-tiles.ts';

export type MapData = {
    opponents: { [key: string]: OpponentScript };
    backgroundImageUrl: string[];
    width: number;
    height: number;
    tiles: TileData[];
}

export type GameData = {
    levels: { [key: string]: MapData };
};

export function loadGameData(): GameData {
    let result: GameData = {
        levels: {}
    };

    for (let levelId of LEVELS) {
        let grid = parseMapTiles(`assets/maps/${levelId}-map.png`);
        let opponents = parseMapOpponents(`assets/maps/${levelId}-data.tsv`, grid.items);
        let backgroundImageUrl = [`assets/maps/${levelId}-bg.png`];

        if (levelId === 'lv3') {
            backgroundImageUrl = [
                `assets/maps/${levelId}-bg_1.png`,
                `assets/maps/${levelId}-bg_2.png`,
            ];
        }

        result.levels[levelId] = {
            width: grid.width,
            height: grid.height,
            tiles: grid.items,
            backgroundImageUrl,
            opponents
        };
    }

    return result;
}